<div class="otp-verification">
    <div class="otp-verification-wrapper">
        <form [formGroup]="otpVerificationForm" (submit)="onVerifyOtpClick()">
            <div class="form-group otp">
                <input (paste)="onOtpInputPaste($event, 0)" (input)="onOtpFieldInputInIndex($event, 0)" (keyup)="onOtpFieldKeyUpInIndex($event, 0)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[0]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl0">
                <input (paste)="onOtpInputPaste($event, 1)" (input)="onOtpFieldInputInIndex($event, 1)" (keyup)="onOtpFieldKeyUpInIndex($event, 1)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[1]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl1">
                <input (paste)="onOtpInputPaste($event, 2)" (input)="onOtpFieldInputInIndex($event, 2)" (keyup)="onOtpFieldKeyUpInIndex($event, 2)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[2]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl2">
                <input (paste)="onOtpInputPaste($event, 3)" (input)="onOtpFieldInputInIndex($event, 3)" (keyup)="onOtpFieldKeyUpInIndex($event, 3)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[3]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl3">
                <input (paste)="onOtpInputPaste($event, 4)" (input)="onOtpFieldInputInIndex($event, 4)" (keyup)="onOtpFieldKeyUpInIndex($event, 4)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[4]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl4">
            </div>

            <div class="message-hint-wrapper text-small">
                <div class="message-hint text-center">{{ langx['FORGOT_PASSWORD_OTP_EXPIRE'] }}</div>
            </div>

            <div class="form-group resend">
                <div class="text-center">
                    <button type="button" class="btn btn-just-text text-small" (click)="onResendOtpClick()" [disabled]="timeRemaining$ | async">
                        {{ langx['FORGOT_PASSWORD_OTP_RESEND'] }}
                        @if(timeRemaining$ | async) {
                            <span class="timer">in {{(timeRemaining$ | async) | date: 'm:ss'}}</span>
                        }
                    </button>
                </div>
            </div>

            <div class="form-group submit">
                <button class="btn btn-primary text-small" [disabled]="otpVerificationForm.invalid">{{ langx['FORGOT_PASSWORD_OTP_VERIFY'] }}</button>
            </div>
        </form>
    </div>
</div>
<app-cs-chat-icon></app-cs-chat-icon>