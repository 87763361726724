import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { LocalizedMessages } from '../../models/i18n.model';
import { ModalButton, ModalContent } from '../../models/modal.model';
import { CommonService } from '../../service/common/common.service';
import { ModalService } from '../../service/modal/modal.service';
import { langxObj } from './modal_langx';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent implements OnDestroy, OnInit {

  constructor(
    private modalService: ModalService,
    private commonService: CommonService
  ) {
    this.modalContent$ = modalService.modalContent$;
    this.modalDisplayToggle$ = modalService.modalDisplayToggle$;
    this.commonService.mediaServer$.subscribe(mediaServer => this.mediaServer = mediaServer);

    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentLanguage => {
        this.langx = langxObj[currentLanguage];
      });
  }
  
  private destroy$: Subject<void> = new Subject();
  langx: LocalizedMessages = {};

  modalContent$: Observable<ModalContent>;
  modalDisplayToggle$: Observable<boolean>;

  mediaServer: string = '';

  ngOnInit(): void {
    this.commonService.preloadImages([
      this.mediaServer + '/zeta/common/icon_circle-error.png',
      this.mediaServer + '/zeta/common/icon_information.png',
      this.mediaServer + '/zeta/common/icon_circle-success.png',
      this.mediaServer + '/zeta/common/icon_confirmation.png',
      this.mediaServer + '/zeta/user/header.png'
    ]);
  }

  onModalButtonClick($event: Event, button: ModalButton) {
    button.callback($event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
