import { Component, Input, Output, EventEmitter, Renderer2, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-clear-input-icon',
  templateUrl: './clear-input-icon.component.html',
  styleUrl: './clear-input-icon.component.scss'
})
export class ClearInputIconComponent {

  constructor(
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef
  ) { }

  shouldShowIcon: boolean = false;

  onCancelIconClick = () => { };
  @Output() clearInput = new EventEmitter<void>();
  @Input() rightPositionClass: string = 'right-small'; // default value
  @Input()
  public set targetInputField(htmlInputElement: HTMLInputElement) {

    this.renderer.listen(htmlInputElement, 'focus', () => {
      this.shouldShowIcon = htmlInputElement.value.length > 0;
    });

    this.renderer.listen(htmlInputElement, 'blur', () => {
      // setTimeout: to give way to onCancelIconClick event before hiding the icon
      setTimeout(() => this.shouldShowIcon = false); 
    });

    this.renderer.listen(htmlInputElement, 'keyup', () => {
      this.shouldShowIcon = htmlInputElement.value.length > 0;
    });

    this.onCancelIconClick = () => {
      this.renderer.setProperty(htmlInputElement, 'value', '');
      htmlInputElement.dispatchEvent(new Event('input')); // Ensure Angular updates
      this.cdRef.detectChanges();
      htmlInputElement.focus();
      if (this.clearInput.observers.length > 0) {
        this.clearInput.emit();
      }
    };
  }

}
