import { InjectionToken } from '@angular/core';

export const APP_USER_AGENT: InjectionToken<string> = new InjectionToken<string>('no-value');
export const HOST_ID: InjectionToken<string> = new InjectionToken<string>('');

export interface BaseResponse<T = any> {
  status: string,
  data?: T,
  errors?: ApiError[],
}

export interface ApiError {
  code: string,
  message: string,
  field: string
}

export enum ErrorCodes {
  Maintenance = 'error.system-maintenance',
  Unauthorized = 'error.unauthorized'
}