import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrl: './custom-modal.component.scss'
})
export class CustomModalComponent {

  @Input() modalDisplayToggle$: Subject<boolean> = new Subject();
  @Input() className: string = '';
  @Input() verticallyCentered: boolean = false;

}
