import { AfterViewChecked, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-marquee',
  templateUrl: './marquee.component.html',
  styleUrl: './marquee.component.scss'
})
export class MarqueeComponent implements AfterViewChecked {

  @Input() playToggle$: Observable<boolean> = new BehaviorSubject<boolean>(true);
  @Input() cssClass: string = '';

  isPlaying: boolean = false;
  animationSpeed: number = 50;
  minSpeed: number = 10;

  @ViewChild('container') elementRef!: ElementRef;
  
  ngAfterViewChecked(): void {
    const offsetWidth = this.elementRef.nativeElement.offsetWidth;

    if(offsetWidth > 0 && !this.isPlaying) {
      let dynamicSpeed = offsetWidth / this.animationSpeed;
      if(dynamicSpeed < this.minSpeed) {
        dynamicSpeed = this.minSpeed;
      }

      this.elementRef.nativeElement.style.animationDuration = dynamicSpeed + 's';
      this.isPlaying = true;
    }
  }

}
