import { Component, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, map, takeUntil, takeWhile, timer } from 'rxjs';
import { VerifyOtpDto } from '../../models/home.model';
import { LocalizedMessages } from '../../models/i18n.model';
import { OtpCallback } from '../../models/otp-verification.model';
import { CommonService } from '../../service/common/common.service';
import { HomeService } from '../../service/home/home.service';
import { ModalService } from '../../service/modal/modal.service';
import { langxObj } from './otp-verification_langx';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrl: './otp-verification.component.scss'
})
export class OtpVerificationComponent {

  @Input() sendOtp!: OtpCallback;
  @Input() onComplete!: OtpCallback;

  @Input() isSMS: boolean | undefined = false;

  destroy$: Subject<void> = new Subject();
  mediaServer$: Observable<string>;

  otpInputFieldFocusSignals$!: any[];
  otpVerificationForm!: FormGroup;
  langx: LocalizedMessages = {};

  verificationCodeControl!: AbstractControl;

  timeRemaining$ = new Observable<number>();

  constructor(
    private homeService: HomeService,
    private modalService: ModalService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
  ) {

    this.mediaServer$ = this.commonService.mediaServer$;

    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (lang) => {
          this.langx = langxObj[lang];
        }
      });

    this.initaliazeotpInputFieldFocusSignals();
    this.initializeOtpVerificationForm();
  }

  initaliazeotpInputFieldFocusSignals(): void {
    this.otpInputFieldFocusSignals$ = [
      new BehaviorSubject<boolean>(true),
      new BehaviorSubject<boolean>(false),
      new BehaviorSubject<boolean>(false),
      new BehaviorSubject<boolean>(false),
      new BehaviorSubject<boolean>(false)
    ];
  }

  initializeOtpVerificationForm() {
    this.otpVerificationForm = this.formBuilder.group({});
    this.otpInputFieldFocusSignals$.forEach((item, index) => {
      const control = new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9]{1}$')])
      this.otpVerificationForm.addControl(`otpControl${index}`, control);
    });
  }

  private verifyOtp(verifyOtpDto: VerifyOtpDto) {
    this.commonService.showLoading();
    this.homeService.verifyOtp(verifyOtpDto).subscribe(response => {
      if (response?.status === 'error') {
        if (response.errors) {
          this.modalService.showError(
            response.errors?.[0].message, 
            this.langx['FORGOT_PASSWORD_OTP_VERIFICATION_FAIL_BUTTON_LABEL']
          );
        }
      } else {
        const successMessage = this.isSMS ? this.langx['FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL_SMS']
          : this.langx['FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL_EMAIL'];
        this.modalService.showSuccess(successMessage,
          this.langx['FORGOT_PASSWORD_OTP_VERIFICATION_SUCCESSFUL_BUTTON_LABEL']
        )
          .subscribe(() => {
            this.onComplete();
          });
      }
      this.commonService.hideLoading();
    });
  }

  onVerifyOtpClick(): void {
    const verifyOtpDto: VerifyOtpDto = {
      otp: Object.keys(this.otpVerificationForm.controls)
        .map(control => this.otpVerificationForm.get(control)?.value)
        .join('')
    };
    this.verifyOtp(verifyOtpDto);
  }

  onOtpInputPaste($event: ClipboardEvent, index: number): void {
    if (index <= (this.otpInputFieldFocusSignals$.length - 1)) {
      const formControls = Object.keys(this.otpVerificationForm.controls).slice(index); //get all controls start @ index
      const values = $event.clipboardData?.getData('text').split('') ?? [];

      for (let i = 0; i < formControls.length; i++) {
        if (index < this.otpInputFieldFocusSignals$.length - 1) {
          this.otpInputFieldFocusSignals$[index].next(true);
          index++;
        }
        this.otpVerificationForm.get(formControls[i])?.setValue(values[i]);
      }
    }
  }

  onOtpFieldInputInIndex($event: Event, index: number) {
    const targetInputValue = ($event.target as HTMLInputElement).value;
    if (targetInputValue.match(/^[A-Za-z0-9]{1}$/g) && index < (this.otpInputFieldFocusSignals$.length - 1)) {
      this.otpInputFieldFocusSignals$[index + 1].next(true);
    }
  }
  
  /**
   * Explicitly used for handling backspace because
   * onInput doesn't register an input event if there's no deleted value 
   * when Backspace is pressed
   * 
   * @param $event 
   * @param index 
   */
  onOtpFieldKeyUpInIndex($event: KeyboardEvent, index: number) {
    const targetInputValue = ($event.target as HTMLInputElement).value;
    if($event.key === 'Backspace' && index > 0 && !targetInputValue) {
      this.otpInputFieldFocusSignals$[index - 1].next(true);
    }
  }

  onResendOtpClick() {
    const countdownDuration = 5 * 60; // 5 minutes
    this.timeRemaining$ = timer(0, 1000).pipe(map(n => (countdownDuration - n) * 1000), takeWhile(n => n >= 0));
    this.otpVerificationForm.reset();
    this.sendOtp();
  }
}
